<template>
    <div class="Awarp">
        <dashCard class="lvscBox12" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">设备利用率(未使用)</template>
            <template slot="aside"></template>
            <div class="card-content1">
                <chartBoard ref="chart1" :option="chartOption1"/>
            </div>
            <div class="card-content2">
                <!-- <span>
                    <font>实际运行时长</font>
                    <font>{{ detailInfo.chart1.value }}h</font>
                </span> -->
                <div class="content-li-line-box subbox">
                    <span :style="{width: detailInfo.chart1.percent +'%'}"/>
                </div>
                <span>
                    <font>实际运行时长</font>
                    <font>{{ detailInfo.chart1.value }}<font style="font-size: 14px; color: #ccc;">/{{ detailInfo.chart1.max }}h</font></font>
                </span>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <lvscBox12  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'lvscBox12',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
            chartBoard: () => import('@/component/chartBoard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                }
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        var dom = this.$refs['chart1']
                        if(!!dom) {
                            dom.handleWindowResize()
                        }
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                searchObj: {},
                chartOption1: {},
                detailInfo: {
                    chart1: {

                    }
                },
                colorList: ["#FF7300","#DBDBDB","#F03040","#D21423"],
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

            handleChange(value) {
            console.log("handleChange",value,this.searchObj)
            this.getData()
        },
        //每月报警预警数
        getData() {
            this.isLoading = true
            this.detailInfo = {}
            var chart1 = {
                min: 0,
                max: 100,
                value: 54.75,
                percent: 54.75
            }
            this.detailInfo.chart1 = chart1
            this.initEchart()
            this.isLoading = false
        },
		initEchart() {
            var {percent} = this.detailInfo.chart1

            var option = {
                animation:true,
                layoutAnimation:false,
                color: ["#DBDBDB","#1191E6"],
                tooltip: {
                    trigger: 'item'
                },
                angleAxis: {
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    min: 0,
                    max: 6.666,
                    // boundaryGap: ['0', '10'],
                    startAngle: 225,
                },
                radiusAxis: {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    data: ['a', 'b', 'c'],
                    z: 10,
                },
                polar: [{
                    center: ['50%', '50%'], //中心点位置
                    radius: '97%' //图形大小
                }],
                series: [
                    {
                        type: 'bar',
                        data: [, , 5],
                        z: 0,
                        silent: true,
                        coordinateSystem: 'polar',
                        barWidth: 10,
                        name: 'C',
                        roundCap: true,
                        color: {
                            type: 'linear',
                            x: 1,
                            y: 0,
                            x2: 0,
                            y2: 0,
                            colorStops: [
                                {
                                    offset: 0, color: '#1191E6' // 0% 处的颜色
                                },
                                {
                                    offset: 0.5, color: '#25B131' // 0% 处的颜色
                                }
                            ],
                            global: false // 缺省为 false
                        },
                        barGap: '-100%',
                    },
                    // 背景
                    {
                        animation: true,
                        name: '设备利用率',
                        type: 'gauge',
                        radius:'100%',
                        center: ['50%', '50%'],
                        splitNumber: 12,
                        // 展示当前进度
                        progress: {
                            show: true,
                            width: 5,
                            itemStyle: {
                                color: '#DBDBDB',
                                fontFamily: 'ABBvoice_WCNSG_Rg',
                            },
                        },
                        // 仪表盘指针
                        pointer: {
                            show: false,
                        },
                        // 仪表盘轴线相关配置
                        axisLine: {
                            show: false,
                        },
                        // 刻度样式
                        axisTick: {
                            show: false,
                        },
                        // 分隔线样式
                        splitLine: {
                            show: false,
                        },
                        // 刻度标签
                        axisLabel: {
                            show: false,
                        },
                        // 表盘中指针的固定点
                        anchor: {
                            show: false,
                        },
                        detail: {
                            show: false,
                        },
                        data: [
                            {
                                value: 100,
                            },
                        ],
                    },
                    // 内容
                    {
                        name: '',
                        type: 'gauge',
                        radius:'100%',
                        center: ['50%', '50%'],
                        min: 0,
                        max: 100,
                        zlevel: 10,
                        splitNumber: 12,
                        // 展示当前进度
                        progress: {
                            show: true,
                            width: 5,
                            itemStyle: {
                                color: '#1191E6',
                                fontFamily: 'ABBvoice_WCNSG_Rg',
                            },
                        },
                        // 仪表盘指针
                        pointer: {
                            show: false,
                        },
                        // 仪表盘轴线相关配置
                        axisLine: {
                            show: false,
                        },
                        // 刻度样式
                        axisTick: {
                            show: false,
                        },
                        // 分隔线样式
                        splitLine: {
                            show: false,
                        },
                        splitNumber: 1,
                        // 刻度标签
                        axisLabel: {
                            show: true,
                            padding: [0, 10, 0, 10],
                            distance: -30,
                        },
                        // 表盘中指针的固定点
                        anchor: {
                            show: false,
                        },
                        detail: {
                            borderRadius: 8,
                            offsetCenter: ['0%', '-15%'],
                            fontSize: 30,
                            fontWeight: 'bolder',
                            formatter: '{value}',
                            color: 'rgba(0,0,0,.65)',
                        },
                        data: [
                            {
                                value: percent,
                                name: '设备利用率(%)'
                            },
                        ],
                    },

                ],
            };

            this.updateChart('chart1', 'chartOption1', option)
        },
        updateChart: function (refName, optionName, option = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option
            setTimeout(() => {
                this.$refs[refName].updateChartView()
            }, 500)
        },

        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>

<style lang="less" scoped>
.lvscBox12 {
    .card-content1 {
            flex: 1;
            height: 100%;
            div{
                width: 100%;
                height: 100%;
            }
    }
    .card-content2 {
            height: 93px;
            width: 100%;
            box-sizing: border-box;
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            padding: 28px 0 0;
            font {
                font-size: 16px;
                font-weight: 500;
                color: #1f1f1f;
                line-height: 20px;
            }
            span {
                display: flex;
                justify-content: space-between;


            }
            .content-li-line-box {
                width: 100%;
                height: 8px;
                display: flex;
                background: #dbdbdb;
                margin: 12px 0 9px;
                span {
                    background: #1191E6;
                    height: inherit;
                    width: 0%;
                    display: inline-block;
                }
            }


        }


}
</style>
